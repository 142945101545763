import { useEffect, useState } from 'react';
import PageHeader from 'src/components/MainLayout/PageHeader';
import Grid from '@mui/material/Unstable_Grid2';
import { Paper, Typography} from '@mui/material';
import Link from 'next/link';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { logError } from 'src/api/payerApi';

const ErrorPage = ({ statusCode, errorMessage }) => {
  const [isErrorLogged, setIsErrorLogged] = useState(false);

  useEffect(() => {
    function onSuccess() {
      if (!isErrorLogged) {
        setIsErrorLogged(true);
      }
    }

    function onError() {}
    if(errorMessage !== undefined)
    {
      logError(
        {
          errorMessage: errorMessage,
          statusCode: statusCode,
          windowLocation: window.location.pathname + window.location.search,
          navigatorUserAgent: navigator.userAgent
        }, onSuccess, onError);
    }
    sessionStorage.clear();
  }, [errorMessage, statusCode, isErrorLogged]);

  return (
    <>
      <PageHeader title="Villa"/>
      <Grid xs={12} sm={6}>
          <Paper elevation={1} sx={{m:'30px', p: '24px', height: '100%', textAlign:"center"}}>
              <>
                <Typography variant="h1" sx={{fontWeight:"bold" }}>Því miður virðist vefurinn okkar ekki vera virka sem skyldi</Typography>
                <SentimentVeryDissatisfiedIcon sx={{fontSize:"700%" }} />
                <Typography sx={{fontWeight:"bold" }}>
                  Hvað er hægt að gera?
                </Typography>
                <Typography sx={{margin:'10px 30%' }}>
                  Þú getur <Link href="/samskipti" className='greenLink'>sent okkur skilaboð</Link> og við höfum samband, eða farið til baka og prófað aftur.              
                </Typography>
              </>
          </Paper>
        </Grid>
    </>
  );
};

export default ErrorPage;