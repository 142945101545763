
import ErrorPage from './ErrorPage';

const CustomErrorPage = ({ statusCode, errorMessage }) => {

  return <ErrorPage statusCode={statusCode} errorMessage={errorMessage} />;
};

CustomErrorPage.getInitialProps = async ({ res, err }) => {
  let statusCode;
  let errorMessage;
  if (res) {
    statusCode = res.statusCode;
    errorMessage = res.error; // Assuming the error message is available in the res object
  } else if (err) {
    statusCode = err.statusCode;
    errorMessage = err.message; // Assuming the error message is available in the err object
  }

  return { statusCode, errorMessage };
};

export default CustomErrorPage;