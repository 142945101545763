import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden, Typography } from '@mui/material';
import pageHeaderLoaderStyles from 'styles/pageHeaderLoader.module.css';

class PageHeader extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
  };

  render() {
    const { title, isLoading, children } = this.props;
    return (
      <Box sx={theme => ({
        pt: '48px',
        pb: '5px',
        [theme.breakpoints.down('sm')]: { pt: '36px' }
      })}>
        <Hidden smDown>
          <Box sx={{float: 'right'}}>{children}</Box>
        </Hidden>
        {isLoading && (
          <div className={pageHeaderLoaderStyles.animatedBarWrap}>
            <div className={pageHeaderLoaderStyles.animatedBarLoader}>...</div>
          </div>
        )}
        <Typography key="headline" variant="h1" sx={{ mt: '0px', '@media print': {mt: '8px'} }}>
          {title}
        </Typography>
      </Box>
    );
  }
}

PageHeader.defaultProps = {
  isLoading: false,
};

export default PageHeader
